
















import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

import MeetingList from "../components/meetings/MeetingList.vue";

@Component({
  components: {
    MeetingList,
  },
})
export default class Meetings extends Vue {
  @Prop({ required: true })
  private meetingType: number;
  @Prop({ required: true })
  private pageTitle: string;

  private upcomingMeetings: any[] = [];
  private pastMeetings: any[] = [];

  public async created() {
    const upcomingResponse = await axios.get("/meetings/upcoming", {
      params: {
        meeting_type_id: this.meetingType,
      },
    });

    const pastResponse = await axios.get("/meetings/past", {
      params: {
        meeting_type_id: this.meetingType,
      },
    });

    this.upcomingMeetings = upcomingResponse.data;
    this.pastMeetings = pastResponse.data;
  }
}
