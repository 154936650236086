
























import {Component, Prop, Vue} from "vue-property-decorator";
import MeetingAttachment from "./MeetingAttachment.vue";
import moment from "moment";

@Component({
  components: {
    MeetingAttachment,
  },
  props: ["meeting", "board"],
})
export default class Meeting extends Vue {
  @Prop()
  public meeting!: any;

  public expanded = false;

  public expand() {
    this.expanded = !this.expanded;
  }

  get startDate() {
    return moment(this.meeting.start_date).format("DD MMMM YYYY");
  }

  get endDate() {
    return moment(this.meeting.end_date).format("DD MMMM YYYY");
  }

  get expandMoreIcon() {
    return this.meeting.files.length > 0 && !this.expanded;
  }

  get expandLessIcon() {
    return this.meeting.files.length > 0 && this.expanded;
  }
}
