









import {Component, Prop, Vue} from "vue-property-decorator";
import axios from "axios";


@Component({
  components: {},
  props: ["file"],
})
export default class MeetingAttachment extends Vue {
  @Prop()
  public file: any;

  private tokenUrl = "/auth/dl-token";
  private dlUrl = "/meeting-files/";

  public download() {
    const newWindow = window.open();
    axios.get(this.tokenUrl, {withCredentials: true}).then(() => {
      newWindow.location.href = axios.defaults.baseURL + this.dlUrl + this.file.id;
    }).catch((err) => {
      newWindow.close();
    });
  }
}
